import React from 'react';
import { useResource } from 'hooks';

const useTriviaQuestions = () => {
	const {
		id: triviaQuestionId,
		isLoading,
		resource: triviaQuestion,
		resources: triviaQuestions,
		errors: triviaQuestionErrors,
		findOne: findTriviaQuestion,
		findMany: findTriviaQuestions,
		create: createTriviaQuestion,
		update: updateTriviaQuestion,
		destroy: destroyTriviaQuestion,
		isEmpty: isEmptyTriviaQuestion,
		...rest
	} = useResource({
		url: '/api/v1/admin/trivia_questions',
		name: 'trivia_question',
		label: 'Trivia Question'
	});

	return {
		triviaQuestionId,
		triviaQuestion,
		triviaQuestions,
		triviaQuestionErrors,
		isLoading,
		findTriviaQuestion,
		findTriviaQuestions,
		createTriviaQuestion,
		updateTriviaQuestion,
		destroyTriviaQuestion,
		isEmptyTriviaQuestion,
		...rest
	};
};

export default useTriviaQuestions;
