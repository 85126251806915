import React from 'react';
import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Subheader } from 'components';
import { useAlerts, useTriviaQuestions, useUploadFileInMemory } from 'hooks';
import TriviaQuestionForm from 'features/trainingPlan/trainingPlan/trivia/TriviaQuestionForm';
import { triviaQuestionValidation } from 'validations/triviaQuestion';

const transformPayload = (originalPayload, trainingPlanId) => {
	const { question, media_type, correct_answer, answers } = originalPayload;

	const transformedAnswers = answers.map((answer, index) => {
		const transformedAnswer = { answer };
		if (index === correct_answer) {
			transformedAnswer.correct = true;
		}
		return transformedAnswer;
	});

	return {
		trivia_questions: { question, media_type, answers: transformedAnswers },
		training_plan_id: trainingPlanId
	};
};

const TriviaQuestionNew = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();
	const {
		handleChange,
		triviaQuestion,
		// setTriviaQuestion,
		createTriviaQuestion
	} = useTriviaQuestions();
	const { showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const valid = triviaQuestionValidation(triviaQuestion);

		// Transform payload with media URLs included if applicable
		const transformedPayload = transformPayload(triviaQuestion, id);

		if (!valid.isValid) {
			showAlertError(valid.messages[0]);
			return;
		}

		console.log('transformedPayload', transformedPayload);
		await createTriviaQuestion(
			{ ...transformedPayload, training_plan_id: id },
			memoryFile
		);

		// navigate(`/training-plans/${id}`);
	};

	const handleUpload = async (file, type) => {
		await handleUploadFile(file, type);
	};

	return (
		<div>
			<Subheader
				title="Add Trivia Question"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<TriviaQuestionForm
					question={triviaQuestion}
					handleChange={handleChange}
					handleUploadFile={handleUpload}
					handleDelete={removeFile}
					memoryFile={memoryFile}
				/>
			</Container>
		</div>
	);
};

export default TriviaQuestionNew;
