import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import clsx from 'clsx';
import { clone } from 'lodash';

import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  makeStyles,
  Typography
} from '@material-ui/core';
import { ArrowRight, Check } from '@material-ui/icons';
import { Label, LineItem, Subheader, TextInput } from 'components';
import { useAlerts, useFeedbackAttempts, useFeedbackRequests, useQueryParams, useTrainingPlanDrills, useTrainingPlans } from 'hooks';
import {
  removeUnderscoresAndCapitalize,
  ellipsis,
  objectMap,
  properCase
} from 'lib/utils';
import FeedbackAttemptForm from './FeedbackAttemptForm';
import { useState } from 'react';

const FeedbackAttemptEdit = ({ ...props }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [tabValue, setTabValue] = React.useState(0);
  const [feedback, setFeedback] = useState({
    comment: '',
    skill_ratings: {}
  });

  const query = useQueryParams()
  const type = query.get('type')
  const status = query.get('status')

  const { findFeedbackRequest, feedbackRequest } = useFeedbackRequests({ url: '' })
  const { createFeedback: createDrillFeedback } =
    useTrainingPlanDrills({ id: feedbackRequest?.type_id });

  const { createFeedback: createPlanFeedback } = useTrainingPlans({ id: feedbackRequest?.type_id });


  const { showAlertSuccess, showAlertError } = useAlerts();


  const handleSaveTrainingPlanFeedback = async () => {
    const response = await createPlanFeedback(feedbackRequest?.type_id, {
      ...feedback,
      feedback_request_id: id
    });

    if (response.status) {
      showAlertSuccess('Feedback sent successfully');
    } else {
      showAlertError('Error sending feedback');
    }

    navigate(`/feedback_attempt/${id}?type=${type}&status=${status}`);
  }


  const handleSaveTrainingPlanDrillFeedback = async () => {
    const response = await createDrillFeedback(feedbackRequest?.type_id, {
      ...feedback,
      submission_id: id
    });

    if (response.status) {
      showAlertSuccess('Feedback sent successfully');
    } else {
      showAlertError('Error sending feedback');
    }

    navigate(`/feedback_attempt/${id}?type=${type}&status=${status}`);
  }

  const handleSkillScoreChange = (e, skill) => {
    const { name, value } = e.target;
    setFeedback({
      ...feedback,
      skill_ratings: { ...feedback.skill_ratings, [skill.toLowerCase()]: value }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback({ ...feedback, [name]: value });
  };

  const handleSaveClick = async () => {
    if (type === "TrainingPlan") {
      handleSaveTrainingPlanFeedback()
    } else {
      handleSaveTrainingPlanDrillFeedback()
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const seen = true

  useEffect(() => {
    if (id) findFeedbackRequest(id, { type, filter: status })
  }, [id])

  useEffect(() => {
    if (feedbackRequest && feedbackRequest.feedback)
      setFeedback(feedbackRequest.feedback);
  }, [feedbackRequest]);


  return (
    <React.Fragment>
      <Subheader
        title="Edit Feedback"
        buttons={[
          <Button
            color="primary"
            variant="contained"
            startIcon={<Check />}
            onClick={handleSaveClick}
          >
            Save
          </Button>
        ]}
      />
      <Container>

        <Paper>
          <Box p={4}>
            <React.Fragment>
              {feedbackRequest?.submissions?.map((submission) => (
                <Grid container key={submission.id}>
                  <Grid className={classes.itemContainer} item xs={12} md={6}>
                    <center className={classes.placeholder}>
                      <Typography variant="caption">Benchmark Video</Typography>
                    </center>
                    <video
                      width="100%"
                      height={'90%'}
                      controls
                      src={submission?.video_url}
                    />
                  </Grid>
                  <Grid className={classes.itemContainer} item xs={12} md={6}>
                    <center className={classes.placeholder}>
                      <Typography variant="caption">
                        Users Uploaded Attempt
                      </Typography>
                    </center>
                    <video
                      width="100%"
                      height={'90%'}
                      controls
                      src={submission?.video_url}
                    />
                  </Grid>
                </Grid>)
              )}

              <Grid item xs={12} sm={12}>
                <Box sx={{ marginTop: 20 }}>
                  <Typography variant="h5">Feedback</Typography>
                  <TextInput
                    value={feedback.comment || ''}
                    label=""
                    name="comment"
                    placeholder="Feedback"
                    type={'text'}
                    multiline={true}
                    handleChange={handleChange}
                    rows={10}
                  />
                </Box>

                {feedbackRequest?.category_names?.map((skill, index) => (
                  <Box sx={{ marginTop: 20 }} key={index}>
                    <Typography variant="h5">{skill}</Typography>
                    <TextInput
                      key={index}
                      value={feedback.skill_ratings[skill.toLowerCase()] || ''}
                      label={skill}
                      name={skill.toLowerCase()}
                      handleChange={(e) => handleSkillScoreChange(e, skill)}
                      placeholder={skill}
                      type={'number'}
                    />
                  </Box>
                ))}
              </Grid>
            </React.Fragment>
          </Box>
        </Paper>
      </Container>
    </React.Fragment>
  );
};

export default FeedbackAttemptEdit;

const useStyles = makeStyles((theme) => ({
  attemptContainer: {
    position: 'relative'
  },
  hr: {
    margin: 20
  },
  button: {
    borderColor: 'black',
    borderWidth: 1,
    position: 'absolute',
    top: 10,
    right: 5
  }
}));
