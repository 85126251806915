import {
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import React from 'react';

import { Image, Label } from 'components';
import { useMenu } from 'hooks';
import { ellipsis, properCase, convertVideoToImage } from 'lib/utils';

const FeedbackAttemptItem = ({
  feedbackAttempt,
  isSelectable,
  selectedIds = [],
  handleSelect,
  handleClick,
  handleEditClick,
  handleDeleteClick,
  status,
  ...props
}) => {
  const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

  const renderMenu = () => (
    <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
      {status !== 'viewed' && (<MenuItem onClick={(e) => handleEditClick(feedbackAttempt, e)}>
        Edit
      </MenuItem>)}
      <MenuItem onClick={(e) => handleDeleteClick(feedbackAttempt, e)}>
        Delete
      </MenuItem>
    </Menu>
  );

  const isSelected = selectedIds.includes(feedbackAttempt.id);
  return (
    <TableRow>
      {isSelectable && (
        <TableCell>
          <Checkbox
            checked={isSelected ? true : false}
            onChange={() => handleSelect(feedbackAttempt)}
            name="feedback_attempt"
            color="primary"
          />
        </TableCell>
      )}
      <TableCell>
        <Button onClick={() => handleClick(feedbackAttempt)}>
          <Image
            src={convertVideoToImage(feedbackAttempt.preview)}
            variant="rounded"
            size={80}
          />
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(feedbackAttempt)}>
          {feedbackAttempt?.title}
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(feedbackAttempt)}>
          {feedbackAttempt.full_name}
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(feedbackAttempt)}>
          {feedbackAttempt.skill_category}
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={() => handleClick(feedbackAttempt)}>
          {feedbackAttempt.created_at}
        </Button>
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={(e) => handleToggleMenu(e)}>
          <MoreHoriz />
          {renderMenu()}
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default FeedbackAttemptItem;
