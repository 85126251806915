import React from 'react';
import { Grid, Box } from '@material-ui/core';
import {
	Autosuggest,
	TextInput,
	ImageUploader,
	VideoUploader
} from 'components';
import { TRAINING_PLAN_TRIVIA_MEDIA_TYPES } from 'lib/constants/trainingPlan';

const TriviaQuestionForm = ({
	question,
	handleChange,
	handleUploadFile,
	handleDelete,
	memoryFile
}) => {
	// Check if media type is 'text' to hide answer media fields
	const isTextMediaType = question.media_type === 'text';
	const isMediaTypeSelected = !!question.media_type;

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<TextInput
					rows={4}
					multiline
					value={question.question || ''}
					label="Trivia Question"
					name="question"
					placeholder="Enter Trivia Question"
					handleChange={handleChange}
				/>
			</Grid>

			<Grid item xs={12}>
				<Autosuggest
					options={TRAINING_PLAN_TRIVIA_MEDIA_TYPES.map((type) => ({
						label: type.label,
						value: type.value
					}))}
					value={question.media_type || ''}
					label="Question Media Type"
					name="media_type"
					placeholder="Select Media Type"
					handleChange={handleChange}
				/>
			</Grid>

			{/* Show Media fields only if media type is selected */}
			{isMediaTypeSelected && (
				<>
					{/* Show media upload fields (Image/Video) only if media type is not 'text' */}
					{!isTextMediaType && (
						<Grid item xs={12}>
							{question.media_type === 'image' ? (
								<ImageUploader
									label="Upload Image"
									imageUrl={question.media_url || memoryFile?.media_url?.url}
									handleUpload={(file) => handleUploadFile(file, 'media_url')}
									handleDelete={() => handleDelete('media_url')}
								/>
							) : (
								<VideoUploader
									label="Upload Video"
									videoUrl={question.media_url || memoryFile?.media_url?.url}
									handleUpload={(file) => handleUploadFile(file, 'media_url')}
									handleDelete={() => handleDelete('media_url')}
								/>
							)}
						</Grid>
					)}

					<Grid item xs={12}>
						<Autosuggest
							options={[1, 2, 3, 4].map((num) => ({
								label: `${num}`,
								value: num
							}))}
							value={question.correct_answer || ''}
							label="Correct Answer"
							name="correct_answer"
							handleChange={handleChange}
						/>
					</Grid>

					{/* Loop through answers array */}
					{(question.answers || ['', '', '', '']).map((answer, index) => (
						<Grid item xs={12} key={index}>
							{/* Render answer text field if media type is 'text' */}
							{isTextMediaType && (
								<TextInput
									label={`Trivia Answer ${index + 1}`}
									name={`answer_${index}`}
									value={answer || ''}
									handleChange={(e) => {
										const updatedAnswers = [
											...(question.answers || ['', '', '', ''])
										];
										updatedAnswers[index] = e.target.value;
										handleChange({
											target: { name: 'answers', value: updatedAnswers }
										});
									}}
								/>
							)}

							{/* Conditional rendering for media fields for each answer */}
							{!isTextMediaType && (
								<Box py={2}>
									{question.media_type === 'image' ? (
										<ImageUploader
											label={`Answer ${index + 1} Image`}
											imageUrl={
												answer[`media_url${index}`] ||
												memoryFile?.[`answer_${index}_media_url`]?.url
											}
											handleUpload={(file) =>
												handleUploadFile(file, `answer_${index}_media_url`)
											}
											handleDelete={() =>
												handleDelete(`answer_${index}_media_url`)
											}
										/>
									) : (
										<VideoUploader
											label={`Answer ${index + 1} Video`}
											videoUrl={
												answer.video_url ||
												memoryFile?.[`answer_${index}_video_url`]?.url
											}
											handleUpload={(file) =>
												handleUploadFile(file, `answer_${index}_video_url`)
											}
											handleDelete={() =>
												handleDelete(`answer_${index}_video_url`)
											}
										/>
									)}
								</Box>
							)}
						</Grid>
					))}
				</>
			)}
		</Grid>
	);
};

export default TriviaQuestionForm;
