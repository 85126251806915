import React, { useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	Container,
	List,
	Paper,
	Typography
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { useTrainingPlans, useNavigation, useLessons, useAlerts } from 'hooks';
import { Modal, Subheader, TabPanel } from 'components';
import TrainingPlanDetails from 'features/trainingPlan/TrainingPlanDetails';
import TrainingPlanLessonSortableList from 'features/trainingPlan/trainingPlan/TrainingPlanLessonSortableList';
import TrainingPlanLessonItem from 'features/trainingPlan/TrainingPlanLessonItem';
import TriviaQuestionDetails from 'features/trainingPlan/trainingPlan/trivia/TriviaQuestionDetails';
import TraininigPlanFeedbackRequests from 'features/trainingPlan/trainingPlan/feedbackRequests/TraininigPlanFeedbackRequests';
import AddLessonModal from './AddLessonModal';
import { orderBy, set } from 'lodash';

const CREATE_BUTTON_CONFIG = [
	{
		url: 'training-plans',
		title: 'Training Plan'
	},
	{
		url: 'lessons',
		title: 'Lesson'
	},
	{
		url: 'trivia_question',
		title: 'Trivia Question'
	}
];

const TrainingPlanShow = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const [lessons, setLessons] = useState([]);
	const [triviaQuestion, setTriviaQuestion] = useState(undefined);
	const [tabValue, setTabValue] = useState(0);
	const [showAddLessonModal, setShowAddLessonModal] = useState(false);
	const [createConfig, setCreateConfig] = useState(CREATE_BUTTON_CONFIG[0]);
	const { showAlertSuccess, showAlertError } = useAlerts();

	const { deleteLesson } = useLessons({});
	const {
		trainingPlan,
		findTrainingPlan,
		isLoading,
		resortTrainingPlanLessons,
		destroyTrainingPlan,
		isLoaded,
		trainingPlans,
		addLesson,
		removeLesson
	} = useTrainingPlans({
		id: id
	});

	const handleTabChange = (_, newValue) => {
		setTabValue(newValue);
		setCreateConfig(CREATE_BUTTON_CONFIG[newValue]);
	};

	const onDragEnd = async (results) => {
		setLessons(results);

		const response = await resortTrainingPlanLessons(results);

		if (response) {
			setLessons(response?.lessons);
		}
	};

	const toggleAddLessonModal = () => setShowAddLessonModal((prev) => !prev);

	const handleAddLesson = async (lesson) => {
		const response = await addLesson(id, lesson.id);

		if (response && response.id) {
			setLessons((prev) => [...prev, response]);
			findTrainingPlan(id);
			toggleAddLessonModal();
			showAlertSuccess('Lesson added successfully');
		} else {
			showAlertError('Error adding lesson');
		}
	};

	const handleEdit = () => {
		navigate(`/training-plans/${trainingPlan.id}/edit`);
	};

	const handleEditClick = (lesson) => {
		navigate(
			`/training-plans/lesson/${lesson.id}/edit?training_plan_id=${trainingPlan.id}`
		);
	};

	const handleClick = (lesson) => {
		navigate(`/training-plans/lesson/${lesson.id}`);
	};

	const handleDeleteClick = async (lesson) => {
		await removeLesson(trainingPlan.id, lesson.id).finally(() => {
			findTrainingPlan(id);
		});
	};

	const handleAddtriviaQuestion = () => {
		navigate(`/training-plans/${trainingPlan.id}/trivia-question/new`);
	};

	const handleEditTriviaQuestion = () => {
		navigate(
			`/training-plans/${trainingPlan.id}/trivia-question/${triviaQuestion.id}/edit`
		);
	};

	const createButton = useMemo(() => {
		if (
			!['lessons', 'submissions', 'trivia_question'].includes(createConfig.url)
		) {
			return (
				<Button
					variant="contained"
					startIcon={'Edit'}
					onClick={handleEdit}
					color="primary"
				>
					{createConfig.title}
				</Button>
			);
		}

		if (createConfig.url === 'lessons') {
			return (
				<Button
					variant="contained"
					startIcon={<Add />}
					onClick={toggleAddLessonModal}
					color="primary"
				>
					{createConfig.title}
				</Button>
			);
		}

		if (createConfig.url === 'trivia_question') {
			return (
				<Button
					variant="contained"
					startIcon={'Edit'}
					onClick={handleEditTriviaQuestion}
					color="primary"
				>
					{createConfig.title}
				</Button>
			);
		}
	}, [createConfig.url]);

	useEffect(() => {
		if (id) {
			findTrainingPlan(id);
		}
	}, [id]);

	useEffect(() => {
		if (trainingPlan?.lessons) {
			setLessons(trainingPlan.lessons);
		}

		if (trainingPlan?.trivia_question) {
			setTriviaQuestion(trainingPlan.trivia_question);
		}
	}, [trainingPlan, isLoading]);

	return (
		<div>
			<Subheader
				title={isLoading ? 'Loading...' : trainingPlan.name}
				buttons={[createButton]}
			/>
			<Container maxWidth="lg">
				<Tabs
					value={tabValue}
					onChange={handleTabChange}
					aria-label="Training Plan"
				>
					<Tab label="Training Plan" />
					<Tab label="Lessons" />
					<Tab label="Trivia Question" />
				</Tabs>
				<Paper>
					<TabPanel value={tabValue} index={0}>
						{trainingPlan && (
							<TrainingPlanDetails trainingPlan={trainingPlan} />
						)}
					</TabPanel>
					<TabPanel value={tabValue} index={1}>
						{lessons?.length > 0 ? (
							<TrainingPlanLessonSortableList
								id="sort-training-plan-lessons"
								onDragEnd={onDragEnd}
								list={orderBy(lessons, ['lesson_position'], ['asc'])}
								ListContainer={List}
								ListItem={({ item, index }) => (
									<TrainingPlanLessonItem
										key={item.id}
										lesson={item}
										index={index}
										handleEditClick={handleEditClick}
										handleDeleteClick={handleDeleteClick}
										handleClick={handleClick}
									/>
								)}
							/>
						) : (
							<Container maxWidth="lg">
								<Box
									p={2}
									display="flex"
									flexDirection="column"
									justifyContent="center"
									alignItems="center"
									height="100%"
									width="100%"
									sx={{ minHeight: 600 }}
								>
									<Typography variant="body1" style={{ marginBottom: 20 }}>
										{'No lessons in this training plan.'}
									</Typography>
									<Button
										onClick={toggleAddLessonModal}
										color="primary"
										variant="contained"
									>
										Add a new lesson
									</Button>
								</Box>
							</Container>
						)}
					</TabPanel>
					<TabPanel value={tabValue} index={2}>
						{triviaQuestion ? (
							<TriviaQuestionDetails
								question={triviaQuestion}
								findTrainingPlan={findTrainingPlan}
							/>
						) : (
							<Container maxWidth="lg">
								<Box
									p={2}
									display="flex"
									flexDirection="column"
									justifyContent="center"
									alignItems="center"
									height="100%"
									width="100%"
									sx={{ minHeight: 600 }}
								>
									<Typography variant="body1" style={{ marginBottom: 20 }}>
										{'No question for this training plan.'}
									</Typography>
									<Button
										onClick={handleAddtriviaQuestion}
										color="primary"
										variant="contained"
									>
										Add a trivia question
									</Button>
								</Box>
							</Container>
						)}
					</TabPanel>
					{/* <TabPanel value={tabValue} index={3}>
						<TraininigPlanFeedbackRequests
							currentPlan={trainingPlan}
							url={`/api/v1/admin/training_plans/${trainingPlan.id}/feedback_requests`}
						/>
					</TabPanel> */}
				</Paper>
			</Container>

			<Modal
				open={showAddLessonModal}
				maxWidth={'lg'}
				title={'Add Lesson'}
				handleClose={toggleAddLessonModal}
			>
				<AddLessonModal id={id} handleAddLesson={handleAddLesson} />
			</Modal>
		</div>
	);
};

export default TrainingPlanShow;
