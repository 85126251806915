import React, { useEffect } from 'react';
import { useEvents, useAlerts, useNavigation, useSelected } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { SortableTableHeader, Subheader, Placeholder } from 'components';
import Pagination from '@material-ui/lab/Pagination';
import { Container, Box, Paper, Typography, Grid } from '@material-ui/core';

import { Add, Search } from '@material-ui/icons';
import EventItem from 'features/events/EventItem';
import EventToolbar from 'features/events/EventToolbar';
import SearchForm from 'features/events/SearchForm';

const TABLE_HEADERS = [
	{
		value: 'image_url',
		label: 'Image',
		sortable: false,
		hidden: false
	},
	{
		value: 'title',
		label: 'Title',
		sortable: true,
		hidden: false
	},
	{
		value: 'start_date',
		label: 'Start date',
		sortable: false,
		hidden: false
	},
	{
		value: 'published',
		label: 'Published',
		sortable: true,
		hidden: false
	}
];

const EventList = ({ ...props }) => {
	const navigate = useNavigate();
	const {
		isLoading,
		isLoaded,
		isEmpty,
		events,
		findEvents,
		deleteEvent,
		paginate,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadEvents,
		sortKey,
		sortDirection,
		handleSort
	} = useEvents({});

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		history,
		url: '/events'
	});

	const {
		selected,
		selectedIds,
		handleSelect,
		handleClear,
		handleSelectAll,
		totalSelected
	} = useSelected();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleDeleteClick = async (event) => {
		await deleteEvent(event);
		showAlertSuccess('Event has been deleted');
		reloadEvents();
	};

	useEffect(() => {
		findEvents(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [query, page, sortKey, sortDirection]);

	console.log('events ', events);

	return (
		<>
			<Container maxWidth="xl">
				<>
					<Paper style={{ padding: 24 }}>
						{!isEmpty ? (
							<Grid container spacing={3}>
								{events.map((event) => (
									<EventItem
										key={event.id}
										event={event}
										isSelectable={false}
										selectedIds={selectedIds}
										handleSelect={handleSelect}
										handleClick={handleClick}
										handleDeleteClick={handleDeleteClick}
										handleEditClick={handleEditClick}
									/>
								))}
							</Grid>
						) : (
							<Placeholder
								icon={<Search />}
								title="No events"
								subtitle="Please try another search"
							/>
						)}
					</Paper>
					{!isEmpty && (
						<Box my={1}>
							<Pagination
								onChange={(ev, page) => paginate(page)}
								color="primary"
								size="small"
								shape="rounded"
								page={page}
								count={numPages}
							/>
						</Box>
					)}
				</>
				<EventToolbar
					selected={selected}
					selectedIds={selectedIds}
					handleClear={handleClear}
					totalSelected={totalSelected}
				/>
			</Container>
		</>
	);
};

export default EventList;
