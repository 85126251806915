import React, { useEffect } from 'react';

import {
	Box,
	Button,
	Container,
	Grid,
	makeStyles,
	Typography
} from '@material-ui/core';
import {
	Check,
	Close,
	CompareArrowsSharp,
	TramSharp
} from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';

import { ImageUploader, Subheader, VideoUploader } from 'components';
import { useAlerts, useTriviaQuestions, useUploadFileInMemory } from 'hooks';
import TriviaQuestionForm from 'features/trainingPlan/trainingPlan/trivia/TriviaQuestionForm';
import { triviaQuestionValidation } from 'validations/triviaQuestion';
import { find } from 'lodash';

const TriviaQuestionEdit = () => {
	const navigate = useNavigate();
	const { id, training_plan_id } = useParams();

	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();
	const {
		handleChange,
		triviaQuestion,
		createTriviaQuestion,
		updateTriviaQuestion,
		findTriviaQuestion,
		destroyTriviaQuestion
	} = useTriviaQuestions();
	const { showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		await updateTriviaQuestion(triviaQuestion, memoryFile);

		navigate(`/training-plans/${training_plan_id}`);
	};

	const handleUpload = async (file, type) => {
		await handleUploadFile(file, type);
	};

	const handleDeleteQuestion = async (question) => {
		await destroyTriviaQuestion(question).finally(() => {
			navigate(`/training-plans/${training_plan_id}`);
		});
	};

	const handleDelete = async (type) => {
		removeFile();
	};

	useEffect(() => {
		if (id) findTriviaQuestion(id);
	}, [id]);

	const classes = useStyles();

	return (
		<div>
			<Subheader
				title="Update Trivia Question"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<TriviaQuestionForm
					question={triviaQuestion}
					handleChange={handleChange}
				/>
				{triviaQuestion.media_type === 'image' && (
					<Box py={4}>
						<Typography variant="body2" color="textSecondary">
							{'Image'}
						</Typography>
						<ImageUploader
							label="Media File"
							imageUrl={triviaQuestion.media_url || memoryFile?.media_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'media_url')}
							handleDelete={() => handleDelete('media_url')}
						/>
					</Box>
				)}

				{triviaQuestion.media_type === 'video' && (
					<Box py={4}>
						<Typography variant="body2" color="textSecondary">
							{'Video'}
						</Typography>
						<VideoUploader
							label="Media File"
							videoUrl={
								triviaQuestion.media_url ||
								(memoryFile && memoryFile?.media_url?.url)
							}
							handleUpload={(file) => handleUpload(file, 'media_url')}
							handleDelete={() => {
								handleDelete('media_url');
								handleChange({ target: { name: 'media_url', value: '' } });
							}}
						/>
					</Box>
				)}
				<div className={classes.container}>
					<div item sm={6} md={6} x={6}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</div>
					<div item sm={6} md={6} x={6} className={classes.item}>
						<Button
							startIcon={<Close />}
							color="secondary"
							variant="outlined"
							onClick={() => handleDeleteQuestion(triviaQuestion)}
						>
							Delete Question
						</Button>
					</div>
				</div>
			</Container>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '20px'
	},
	item: {
		alignSelf: 'flex-start'
	}
}));

export default TriviaQuestionEdit;
