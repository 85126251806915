import React from 'react';

import {
	Button,
	Grid,
	IconButton,
	makeStyles,
	Typography
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { DisplayImage } from 'components';

const AnswerCard = ({
	answer,
	handleChange,
	index,
	handleAddAnswer,
	correnctAnswerId,
	handleDeteleAnswer,
	handleEditAnswer
}) => {
	const classes = useStyles();

	return (
		<Grid
			item
			xs={12}
			md={5}
			sm={5}
			className={
				answer && answer.id === correnctAnswerId
					? classes.cardCorrect
					: classes.card
			}
		>
			<center>
				<Typography variant="body1" style={{ marginBottom: 20 }}>
					{`Answer ${index + 1}`}
				</Typography>
			</center>
			{answer ? (
				<>
					<Grid container spacing={1} className={classes.imageContainer}>
						<Grid item xs={12} sm={12}>
							{answer.media_type === 'image' && (
								<center>
									<img
										src={answer?.media_url}
										width={'100%'}
										className={classes.image}
									/>
								</center>
							)}
							{answer.media_type === 'video' && (
								<center>
									<video
										src={answer?.media_url}
										controls
										muted
										height="200"
										width="auto"
									/>
								</center>
							)}

							{answer.media_type === 'text' && (
								<center>
									<Typography variant="body1" style={{ marginBottom: 20 }}>
										{answer.answer}
									</Typography>
								</center>
							)}
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid item xs={6} sm={6}>
							<Button
								variant="contained"
								color="primary"
								fullWidth
								onClick={() => handleEditAnswer(answer)}
							>
								Edit Answer
							</Button>
						</Grid>
						<Grid item xs={6} sm={6}>
							<Button
								variant="contained"
								color="secondary"
								fullWidth
								onClick={() => handleDeteleAnswer(answer)}
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</>
			) : (
				<></>
			)}
		</Grid>
	);
};

export default AnswerCard;

const useStyles = makeStyles((theme) => ({
	card: {
		border: `1px solid ${theme.palette.grey[300]}`,
		margin: 10,
		minHeight: 260,
		borderRadius: 10
	},
	cardCorrect: {
		border: `2px solid ${theme.palette.primary.main}`,
		margin: 10,
		minHeight: 260,
		borderRadius: 10
	},
	imageContainer: {
		flex: 1,
		height: 240
	},
	image: {
		objectFit: 'cover',
		height: 220
	},
	actionButtons: {}
}));
