import { isEmpty } from 'validations';

export const triviaAnswerValidation = (answer) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(answer.answer)) {
		isValid = false;
		messages.push('Answer is required');
	}

	if (isEmpty(answer.media_type)) {
		isValid = false;
		messages.push('Media Type is required');
	}

	return {
		isValid,
		messages
	};
};
