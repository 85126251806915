import { isEmpty } from 'validations';

export const triviaQuestionValidation = (question) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(question.question)) {
		isValid = false;
		messages.push('Question is required');
	}

	if (isEmpty(question.media_type)) {
		isValid = false;
		messages.push('Media Type is required');
	}

	return {
		isValid,
		messages
	};
};
