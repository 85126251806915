import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Container, Paper, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { useTrainingPlanDrills } from 'hooks';
import { Subheader, TabPanel } from 'components';
import TrainingPlanDetails from 'features/trainingPlan/TrainingPlanDetails';
import TrainingPlanLessonDetails from 'features/trainingPlan/TrainingPlanLessonDetails';
import { configure } from 'test-utils';
import Submissions from 'features/trainingPlan/trainingPlanDrill/submissions/TrainingPlanDrillSubmissions';
import TrainingPlanDrillDetails from 'features/trainingPlan/trainingPlanDrill/TrainingPlanDrillDetails';

const CREATE_BUTTON_CONFIG = [
	{
		url: 'training-plan-drills',
		title: 'Training Plan Drill'
	},
	{
		url: 'lessons',
		title: 'Lesson'
	},
	{
		url: 'submissions',
		title: 'Submissions'
	}
];

const TrainingPlanDrillShow = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [lesson, setLesson] = useState(undefined);
	const [tabValue, setTabValue] = useState(0);
	const [createConfig, setCreateConfig] = useState(CREATE_BUTTON_CONFIG[0]);

	const { trainingPlanDrill, findTrainingPlanDrill, isLoading } =
		useTrainingPlanDrills({ id: id });

	const handleTabChange = (_, newValue) => {
		setTabValue(newValue);
		setCreateConfig(CREATE_BUTTON_CONFIG[newValue]);
	};

	const handleEdit = () => {
		if (createConfig.url === 'lessons' && lesson?.id) {
			navigate(
				`/training-plans/lesson/${lesson.id}/edit?training_plan_drill_id=${trainingPlanDrill.id}`
			);
		} else if (createConfig.url === 'lessons' && !lesson?.id) {
			return;
		} else {
			navigate(`/training-plan-drills/${trainingPlanDrill.id}/edit`);
		}
	};


	const createButton = useMemo(
		() => (
			<Button
				variant="contained"
				startIcon={'Edit'}
				onClick={handleEdit}
				color="primary"
			>
				{'Benchmark Drill'}
			</Button>
		),
		[]
	);

	useEffect(() => {
		if (id) {
			findTrainingPlanDrill(id);
		}
	}, [id]);

	return (
		<div>
			<Subheader
				title={isLoading ? 'Loading...' : trainingPlanDrill.name}
				buttons={[createButton]}
			/>
			<Container maxWidth="lg">
				<Paper>
					<Box p={2}>
						<TrainingPlanDrillDetails trainingPlan={trainingPlanDrill} />
					</Box>
				</Paper>
			</Container>
		</div>
	);
};

export default TrainingPlanDrillShow;
